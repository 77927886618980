import classnames from 'classnames';
import React from 'react';

import styles from './manage365.module.scss';

import { OFFICE365_CONTROL_PANEL } from '../../links.js';
import Callout from '../../components/callout.js';
import ExternalLink from '../../components/externalLink';
import Layout from '../../components/layout.js';
import SEO from '../../components/seo.js';
import cp365Screenshot from '../../graphics/cp365-screenshot.png';
import { trackCustomEvent } from '../../utils/googleAnalytics.js';

const icon = (type, styleClass = '') => () => (
  <hx-icon class={styleClass} type={type} />
);

const Manage365 = () => {
  return (
    <Layout enableLogin={false}>
      <SEO
        title="Manage 365"
        description="Microsoft 365 with Rackspace Technology"
      />
      <div className="hxRow">
        <div className="hxCol">
          <h1>Microsoft 365 with Rackspace Technology</h1>
        </div>
      </div>
      <article className="hxRow">
        <div className="hxCol hxSpan-12-md hxSpan-7-lg">
          <div className={classnames('hxRow', styles.description)}>
            <div className="hxCol">
              <h2>Helping You Achieve Business Goals</h2>
              <p className={styles.description}>
                We offer Microsft 365, Dynamics 365 and Azure all backed by the
                Rackspace Fanatical Experience.
              </p>
            </div>
          </div>
          <section className="hxRow">
            <Callout
              icon={icon('server-config', styles.icon)}
              size={'md:w-1/2'}
              title="Premium Control Panel"
            >
              <p className={styles.description}>
                Simplified administrative tasks using a control panel experience
                for easy and efficient management.
              </p>
            </Callout>
            <Callout
              icon={icon('user', styles.icon)}
              size={'md:w-1/2'}
              title="Manage Products and Users"
            >
              <p className={styles.description}>
                Purchase Microsoft 365 and Dynamics 365 products and assign to
                users all under ONE platform. Find the collaboration and
                productivity solution that fits your needs and your budget.{' '}
                <ExternalLink
                  href="https://94dee89e77db24588861-ea1fdf0793cc46b9e1549fb01905d8b8.ssl.cf1.rackcdn.com/pdfs/Rackspace-Data-Sheet-Microsoft-365-Plans-and-Pricing-Sheet-CLO-TSK-2425.pdf"
                  onClick={() =>
                    trackCustomEvent({
                      action: 'Click',
                      category: 'Azure-M365 Pages',
                      label: 'Compare All Plans',
                    })
                  }
                >
                  Compare all plans
                </ExternalLink>
                .
              </p>
            </Callout>
          </section>
          <section className="hxRow">
            <Callout
              icon={icon('support', styles.icon)}
              size={'md:w-1/2'}
              title="24x7x365 Expert Support"
            >
              <p className={styles.description}>
                Get around-the-clock access to our award-winning support with
                unlimited support requests and no per-incident charges.
              </p>
            </Callout>
            <Callout
              icon={icon('ticketing', styles.icon)}
              size={'md:w-1/2'}
              title="Migration Assistance"
            >
              <p className={styles.description}>
                Migrating your business-critical data can be daunting. Let our
                team of migration and onboarding specialists help you{' '}
                <ExternalLink
                  href="https://www.rackspace.com/microsoft/office-365/transformation-services"
                  onClick={() =>
                    trackCustomEvent({
                      action: 'Click',
                      category: 'Azure-M365 Pages',
                      label: 'Plan and Execute Your Migration',
                    })
                  }
                >
                  plan and execute your migration
                </ExternalLink>{' '}
                to the 365 solution of your choice.
              </p>
            </Callout>
          </section>
        </div>
        <aside className="hxCol hxSpan-6-md hxSpan-5-lg">
          <div className={styles.sidebar}>
            <h3 className={classnames('text-2xl', styles.sidebarHeading)}>
              Faster. Simpler. Better.
            </h3>
            <p className={styles.description}>
              Our optimized control panel makes performing administrative tasks
              a breeze.{' '}
            </p>
            <img
              className={styles.cp365Image}
              src={cp365Screenshot}
              width="106%"
              alt="Office 365 Control Panel"
            />
            <p className={styles.description}>
              Our experts can help you pick the right plan for your business. No
              long-term contracts and you can cancel at any time with one click.
            </p>
            <h3 className={styles.sendFeedback}>
              <ExternalLink href={'mailto:microsoft_inquiry@rackspace.com'}>
                Send us an email at <p>microsoft_inquiry@rackspace.com</p>
              </ExternalLink>
            </h3>
            <p className={styles.description}>
              <em>or contact your account manager.</em>
            </p>
            <p>
              Already a Rackspace Microsoft 365 customer?{' '}
              <ExternalLink
                href={OFFICE365_CONTROL_PANEL}
                onClick={() =>
                  trackCustomEvent({
                    action: 'Click',
                    category: 'Azure-M365 Pages',
                    label: 'Manage 365 Rackspace Admin Portal Text Link',
                  })
                }
              >
                Log in here.
              </ExternalLink>
            </p>
          </div>
        </aside>
      </article>
    </Layout>
  );
};

export default Manage365;
